import React, { useState, useEffect } from 'react';
import './FDAHomeMain.css';
import jsonData from './parts_subparts_identifiers.json'; // Adjust the path if needed
import { env } from './environment';

export default function AdminPanel() {
  const [cards, setCards] = useState([]);
  const [parts, setParts] = useState([]);
  const [subparts, setSubparts] = useState([]);
  const [identifiers, setIdentifiers] = useState([]);
  const [selectedPart, setSelectedPart] = useState('');
  const [selectedSubpart, setSelectedSubpart] = useState('');
  const [selectedIdentifier, setSelectedIdentifier] = useState('');

  useEffect(() => {
    // Fetch card details from the Flask API when the component mounts
    fetch(`${env.baseurl}/sql/cards`)
      .then(response => response.json())
      .then(data => setCards(data))
      .catch(error => console.error('Error fetching cards:', error));

    // Initialize parts from the imported JSON data
    const partsData = jsonData.map(item => item.part).filter(part => part !== undefined);
    setParts(partsData);
  }, []);

  const handlePartChange = (event) => {
    const part = event.target.value;
    setSelectedPart(part);

    // Find the selected part's subparts
    const selectedPartData = jsonData.find(item => item.part === part);
    if (selectedPartData) {
      setSubparts(selectedPartData.subparts.map(sub => sub.subpart));
    } else {
      setSubparts([]);
    }
    // Reset subpart and identifier when part changes
    setSelectedSubpart('');
    setSelectedIdentifier('');
  };

  const handleSubpartChange = (event) => {
    const subpart = event.target.value;
    setSelectedSubpart(subpart);

    // Find the selected subpart's identifiers
    const selectedPartData = jsonData.find(item => item.part === selectedPart);
    if (selectedPartData) {
      const selectedSubpartData = selectedPartData.subparts.find(sub => sub.subpart === subpart);
      if (selectedSubpartData) {
        setIdentifiers(selectedSubpartData.identifiers);
      } else {
        setIdentifiers([]);
      }
    } else {
      setIdentifiers([]);
    }
    // Reset identifier when subpart changes
    setSelectedIdentifier('');
  };

  const handleIdentifierChange = (event) => {
    setSelectedIdentifier(event.target.value);
  };

  const addCard = () => {
    const newCard = {
      part: selectedPart || null,
      subpart: selectedSubpart || null,
      identifier: selectedIdentifier || null
    };

    // Send POST request to the Flask API
    fetch(env.baseurl + '/sql/post_cards', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCard),
    })
      .then(response => response.json())
      .then(data => {
        if (data.message) {
          // Successfully added card, update the local state
          setCards([...cards, newCard]);
        } else {
          console.error('Error adding card:', data.error);
        }
      })
      .catch(error => console.error('Error:', error));
  };


  return (
    <div className='Admin-Container'>
      <div className="header-reg">ADMIN PANEL</div>

      <div>
        <label htmlFor="part-select">Select Part:</label>
        <select id="part-select" value={selectedPart} onChange={handlePartChange}>
          <option value="">--Select a Part--</option>
          {parts.map((part, index) => (
            <option key={index} value={part}>{part}</option>
          ))}
        </select>
      </div>


      {selectedPart && (
        <div>
          <label htmlFor="subpart-select">Select Subpart:</label>
          <select id="subpart-select" value={selectedSubpart} onChange={handleSubpartChange}>
            <option value="">--Select a Subpart--</option>
            {subparts.map((subpart, index) => (
              <option key={index} value={subpart}>{subpart}</option>
            ))}
          </select>
        </div>
      )}

      {selectedSubpart && (
        <div>
          <label htmlFor="identifier-select">Select Identifier:</label>
          <select id="identifier-select" value={selectedIdentifier} onChange={handleIdentifierChange}>
            <option value="">--Select an Identifier--</option>
            {identifiers.map((identifier, index) => (
              <option key={index} value={identifier}>{identifier}</option>
            ))}
          </select>
        </div>
      )}

      <div>
        <button className="add-card-button" onClick={addCard}>Add Card</button>
        <div>
          {cards.map((card, index) => (
            <div key={index} className="card1">
              <div>
                <h3>Card {index + 1}</h3>
                <p>Part: {card.part}</p>
                <p>Subpart: {card.subpart}</p>
                <p>Identifier: {card.identifier}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}











