import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FDARecentChanges.css'; // Import CSS file for styling
import { env } from './environment';

const FDARecentChanges = () => {
  const [changes, setChanges] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [limit] = useState(10);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    fetchData(page);
  }, [page]);

  const fetchData = async (page) => {
    setLoading(true); // Start loading
    try {
      const response = await axios.get(`${env.baseurl}/recent_changes/recent_changes?page=${page}&limit=${limit}`);
      setChanges(response.data.data);
      setTotal(response.data.total);
      setLoading(false); // End loading
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false); // End loading even on error
    }
  };

  const handlePrevPage = () => {
    setPage(prev => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    if (page * limit < total) {
      setPage(prev => prev + 1);
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="horizontal-loader"></div> // Show horizontal loader while loading
      ) : (
        <>
          {changes.map((section, index) => (
            <div key={index} className="section-box1">
              <h2 className="section-header">{section.date}</h2>
              <p>{section.count} sections changed</p>
              <ul className="change-list">
                {section.changes.map((change, idx) => (
                  <li key={idx} className="change-item">
                    <strong>Part:</strong> {change.part} <br />
                    <strong>Subpart:</strong> {change.subpart} <br />
                    <strong>Identifier:</strong> {change.identifier} <br />
                    <strong>Name:</strong> {change.name}
                  </li>
                ))}
              </ul>
            </div>
          ))}

          <div className="pagination-controls">
            <button className="view-changes-button2 hover-effect" onClick={handlePrevPage} disabled={page === 1}>Previous</button>
            <button className="view-changes-button2 hover-effect" onClick={handleNextPage} disabled={page * limit >= total}>Next</button>
          </div>
        </>
      )}
    </div>
  );
};

export default FDARecentChanges;
