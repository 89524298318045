import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FDARecentChanges.css';
import { env } from './environment';
const App = () => {
  const [changes, setChanges] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    axios.get(`${env.baseurl}/notification/query?title_number=21`)
      .then(response => {
        setChanges(response.data);
        setLoading(false); // Data loaded
      })
      .catch(error => {
        setError('Error fetching data');
        setLoading(false); // Stop loading on error
        console.error('There was an error!', error);
      });
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="horizontal-loader"></div>
        {/* <p>Loading data...</p> */}
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      {changes.length > 0 ? (
        changes.map((change, index) => (
          <div key={index} className="section-box1">
            <h2 className="section-header">{change.date}</h2>
            <strong><p>Title 21—{change.sections_changed} section{change.sections_changed > 1 ? 's' : ''} changed</p></strong>
            {change.details.map((detail, idx) => (
              <div key={idx}>
                <p>Part {detail.part}</p>
                <p>Subpart {detail.subpart}</p>
                <p>{detail.name}</p>
              </div>
            ))}
          </div>
        ))
      ) : (
        <p>No recent changes found for Title 21.</p>
      )}
    </div>
  );
};

export default App;
