import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import MenuBar from './MenuBar'; // Import the MenuBar component
import FDA_Compare from './components/FDA_Compare';
import FDA_Notification from './components/FDA_Notification';
import FDARecentChanges from './components/FDARecentChanges';
import FDAHomeMain from './components/FDAHomeMain';
import AdminPanel from './components/AdminPanel';

const App = () => {
  return (
    <Router>
      <div className="App">
        <MenuBar />
        <Routes>
          <Route path="/" element={<FDAHomeMain />} />
          <Route path="/compare" element={<FDA_Compare />} />
          <Route path="/notification" element={<FDA_Notification />} />
          <Route path="/recentchanges" element={<FDARecentChanges />} />
          <Route path="/admin-panel" element={<AdminPanel />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
