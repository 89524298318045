// TRENDING SEARCHES


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FDA_Compare.css';
import titlesData from './titles_data.json';
import { env } from './environment';

const STOP_WORDS = new Set([
  'what', 'is', 'tell', 'me', 'the', 'of', 'and', 'a', 'in', 'to', 'for', 'on', 'with', 'as', 'at', 'by', 'an', 'this', 'that', 'which', 'or', 'but', 'so', 'then', 'because', 'if', 'when', 'where', 'how', '?', ',', '.', '!'
]);

const filterStopWords = (text) => {
  return text.split(' ').filter(word => !STOP_WORDS.has(word.toLowerCase())).join(' ');
};

const getSuggestions = (input, titles, selected) => {
  if (!input) return [];
  const normalizedInput = filterStopWords(input.toLowerCase());
  return titles
    .filter(title =>
      filterStopWords(title.toLowerCase()).includes(normalizedInput) &&
      !selected.includes(title)
    );
};

const flattenTitles = (data) => {
  return data.flatMap(chapter =>
    [
      chapter.title,
      ...(chapter.subparts || []).flatMap(subpart =>
        [
          subpart.title,
          ...(subpart.parts || []).flatMap(part =>
            [
              part.title,
              ...(part.subparts || []).flatMap(subsubpart =>
                [
                  subsubpart.title,
                  ...(subsubpart.sections || [])
                ]
              ),
              ...(part.sections || [])
            ]
          )
        ]
      )
    ]
  );
};

const TRENDING_SEARCHES = [
  "PART 11—ELECTRONIC RECORDS",
  "Medical devices",
  "Regulations for medical devices manufacturing",
  "CFR Part 11",
  "IQ testing in FDA",
  "OQ and PQ testing for FDA"
];

export default function FDACompare() {
  const [query, setQuery] = useState('');
  const [compareDate, setCompareDate] = useState('');
  const [result, setResult] = useState(null);
  const [current_date, setCurrent_date] = useState(null);
  const [comparisonResult, setComparisonResult] = useState(null);
  const [error, setError] = useState(null);
  const [dates, setDates] = useState([]);
  const [titles, setTitles] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestions, setSelectedSuggestions] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isChatGPTResult, setIsChatGPTResult] = useState(false);
  const [isFirstSubmission, setIsFirstSubmission] = useState(true);

  useEffect(() => {
    setTitles(flattenTitles(titlesData));
  }, []);

  useEffect(() => {
    if (query) {
      const newSuggestions = getSuggestions(query, titles, selectedSuggestions);
      setSuggestions(newSuggestions);
    } else {
      setSuggestions([]);
    }
  }, [query, titles, selectedSuggestions]);

  const fetchDatesAndTitles = async () => {
    try {
      // const datesResponse = await axios.get('https://abkdjk-klwdken-dksnkwkf-nd-ddnam.onrender.com/compare/available_dates');
      const datesResponse = await axios.get(`${env.baseurl}/compare/available_dates`);
      setDates(datesResponse.data.dates);
    } catch (error) {
      console.error('Error fetching dates:', error);
    }
  };

  const fetchAnswerFromFlask = async () => {
    try {
      const response = await axios.post(`${env.baseurl}/ask/chatgpt`, { question: query });
      return response.data.answer;
    } catch (error) {
      console.error('Error fetching answer from Flask:', error);
      return 'Error: Unable to fetch answer.';
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setQuery(suggestion);
    setSuggestions([]);
    setSelectedSuggestions(prev => [...prev, suggestion]);
    setShowResults(false);
    setIsChatGPTResult(false);
  };

  const handleTrendingSearchClick = (search) => {
    setQuery(search);
    setSuggestions([]);
    setShowResults(false);
    setIsChatGPTResult(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    setError(null);
    setResult(null);
    setCurrent_date(null);
    setComparisonResult(null);
    setLoading(true);

    const suggestionsMatch = getSuggestions(query, titles, selectedSuggestions);

    if (suggestionsMatch.length > 0 || selectedSuggestions.includes(query)) {
      try {
        const response = await axios.post(`${env.baseurl}/compare/query`, {
          query,
          date: compareDate
        });

        if (response.data.error) {
          setError(response.data.error);
        } else {
          setResult(response.data.result);
          setCurrent_date(response.data.current_date);
          if (response.data.comparison) {
            setComparisonResult(response.data.comparison);
          }
          setShowResults(true);
          await fetchDatesAndTitles();
          setIsChatGPTResult(false);
        }
      } catch (error) {
        setError('An error occurred while fetching the data.');
      }
    } else {
      try {
        const modifiedQuery = `${query}`;
        const response = await axios.post(`${env.baseurl}/ask/chatgpt`, { question: modifiedQuery });
        setResult(response.data.answer);
        setIsChatGPTResult(true);
        setShowResults(true);
      } catch (error) {
        setError('An error occurred while fetching the answer from ChatGPT.');
      } finally {
        setLoading(false);
      }
    }

    setLoading(false);
    setIsFirstSubmission(false);
  };

  const handleClear = () => {
    setQuery('');
    setCompareDate('');
    setResult(null);
    setCurrent_date(null);
    setComparisonResult(null);
    setSuggestions([]);
    setSelectedSuggestions([]);
    setError(null);
    setShowResults(false);
    setIsChatGPTResult(false);
    setIsFirstSubmission(true);
  };

  return (
    <div className="FDA_Compare">
      <div className='Title-heading'>FDA TITLE-21 SEARCH</div>
      <div className="query-input-area">
        <input
          type="text"
          placeholder="Enter your query"
          value={query}
          onKeyDown={handleKeyDown}
          onChange={(e) => setQuery(e.target.value)}
          className="input-box"
        />
        {query && suggestions.length > 0 && (
          <ul className="suggestions">
            {suggestions.map((suggestion, index) => (
              <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}
        <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: "10px", marginTop: "10px", flexDirection: "column" }}>
          {showResults && !isChatGPTResult && (
            <div style={{ display: 'flex', justifyContent: "center", alignItems: "center", gap: "10px", width: "100%", marginTop: "10px", flexDirection: "column" }}>
              <select
                value={compareDate}
                onChange={(e) => setCompareDate(e.target.value)}
                className="select-box"
              >
                <option value="" disabled>Select a comparison date</option>
                {dates.map((date, index) => (
                  <option key={index} value={date}>{date}</option>
                ))}
              </select>
            </div>
          )}
          <button
            onClick={handleSubmit}
            className="submit-button"
            disabled={loading}
          >
            {isFirstSubmission ? (loading ? 'Submitting...' : 'Submit Query') : 'Search'}
          </button>
          {query && (
            <button
              className="clear-button"
              onClick={handleClear}
              aria-label="Clear query"
            >
              Clear
            </button>
          )}
        </div>
        {/* Trending Searches placed at the bottom */}
        {!query && (
          <div className="trending-searches">
            <div className="trending-heading">Trending Searches:</div>
            <ul>
              {TRENDING_SEARCHES.map((search, index) => (
                <li key={index} onClick={() => handleTrendingSearchClick(search)}>
                  {search}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {loading && (
        <div className="loading-container">
          <div className="loader"></div>
        </div>
      )}
      {error && (
        <div className="error-box">
          {error}
        </div>
      )}
      {result && (
        <div className="results-comparison-container">
          <div className="result">
            <div>{!isChatGPTResult && current_date && <div><div style={{ fontSize: '20px', fontWeight: 'bold' }}>Result</div>
              <div style={{ fontStyle: "italic" }}> ({current_date})</div></div>}</div>
            <pre style={{ fontFamily: "serif", fontSize: "16px", textAlign: "left" }}>
              {result}
            </pre>
          </div>
          {comparisonResult && !isChatGPTResult && (
            <div className="comparison">
              <div><div style={{ fontSize: '20px', fontWeight: 'bold' }}> Comparison Result </div>
                <div style={{ fontStyle: "italic" }}>({compareDate})</div>
              </div>
              <pre style={{ fontFamily: "serif", fontSize: "16px", textAlign: "left" }}>{comparisonResult}</pre>
            </div>
          )}
        </div>
      )}
    </div>
  );
}















