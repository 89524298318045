import React from 'react';
import { NavLink } from 'react-router-dom'; // Import NavLink instead of Link
import './MenuBar.css'; // Import CSS for styling
import logo from './logo.png'; // Import the logo image
import image from './bgimg.png';

const MenuBar = () => {
  return (
    <nav className="menu-bar" 
    
    >
      <ul className="menu-list">
        <li className="menu-item1">
          <NavLink to="/" exact activeClassName="active">
            <img src={logo} alt="Vaisesika Logo" className="logo-image" />
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink to="/" exact activeClassName="active">
            Home
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink to="/compare" activeClassName="active">
            Chatbot
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink to="/notification" activeClassName="active">
            Notification 🔔
          </NavLink>
        </li>
        <li className="menu-item">
          <NavLink to="/recentchanges" activeClassName="active">
            Recent Changes
          </NavLink>
        </li>
        {/* <li className="menu-item">
          <NavLink to="/admin-panel" exact activeClassName="active">
            Admin Panel
          </NavLink>
        </li> */}
      </ul>
    </nav>
  );
};

export default MenuBar;
