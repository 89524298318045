import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FDAHomeMain.css';
import { useNavigate } from 'react-router-dom';
import { env } from './environment';
export default function FDAHomeMain() {
  const [titles, setTitles] = useState([]);
  const [recentChanges, setRecentChanges] = useState([]);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`${env.baseurl}/home/titles`)
      .then(response => {
        setTitles(response.data);
        setLoading(false); // Set loading to false after data is loaded
      })
      .catch(error => {
        console.error('There was an error fetching the titles!', error);
        setLoading(false); // Even on error, stop loading
      });
  }, []);

  const handleCardClick = () => {
    navigate('/compare');
  };

  const handleViewChanges = (titleNumber) => {
    if (titleNumber === 21) {
      navigate('/recentchanges');
    }
  };

  return (
    <div>
      <div className="header-reg">REGULATIONS</div>

      <div className={` ${selectedTitle}`}>
        <div className='cumulative-div'>
          {/* Card is displayed first */}
          <div className="card clickable-card" onClick={handleCardClick}>
            <div className="card-content">
              <h3>U.S. Food & Drug Administration(FDA)</h3> {/* Heading for Card 1 */}
              <div className="card-item">
                <strong>Title:</strong> Title 21
              </div>
              <div className="card-item">
                <strong>Description:</strong>
                {loading ? (
                  <span className="inline-loader">
                    <div className="loader-bar"></div>
                  </span>
                ) : (
                  titles[20]?.name || 'N/A'
                )}
              </div>
              <div className="card-item">
                <strong>Last Amended:</strong>
                {loading ? (
                  <span className="inline-loader">
                    <div className="loader-bar"></div>
                  </span>
                ) : (
                  titles[20]?.latest_amended_on || '--'
                )}
              </div>
              <div className="card-item">
                <button
                  className="view-changes-button hover-effect"
                  onClick={(e) => { e.stopPropagation(); handleViewChanges(21); }}
                  disabled={loading} // Disable button while loading
                >
                  {loading ? 'Loading...' : 'View Recent Changes'}
                </button>
              </div>
            </div>
          </div>

          {/* Dummy Cards can remain here if needed */}
        </div>

        {/* Loading spinner */}
        {loading && (
          <div className="loading-spinner">
            <div className="spinner"></div>
            {/* <p>Loading data...</p> */}
          </div>
        )}
      </div>

      {selectedTitle && (
        <div className="right-panel">
          <h2>Recent Changes for Title {selectedTitle}</h2>
          {recentChanges.length > 0 ? recentChanges.map(change => (
            <div key={change.date} className="section-box">
              <h3>{change.date}</h3>
              <h4>{change.sections.length} section(s) changed</h4>
              {change.sections.map((section, index) => (
                <div key={index}>
                  <p>Part {section.part}</p>
                  <p>Subpart {section.subpart}</p>
                  <p>{section.name}</p>
                </div>
              ))}
            </div>
          )) : <p>No recent changes found.</p>}
        </div>
      )}
    </div>
  );
}
